import React, {Fragment} from "react"
import Proptypes from "prop-types"
import HtmlParser from "react-html-parser"

export default function Adviser(props) {
	const {
		name,
		code,
		certification_name,
		professional_state,
		state,
		description,
		facebook,
		linkedin,
		twitter,
		publicUrl,
		showPublicUrl
	} = props

	const setAssociateCode = certification => {
		if (certification === "EIP") {
			return "Nº de registro"
		}
		return "Nº Asociado"
	}

	const renderName = () => {
		if (!name) return <Fragment/>
		if (showPublicUrl) return <a href={`/asesor-financiero?pu=${publicUrl}`} target="_blank" rel="noreferrer"
									 className="Adviser-name d-block">{name}</a>
		return <span className="Adviser-name d-block">{name}</span>
	}

	const renderCode = () => {
		if (!code) return <Fragment/>
		return (
			<span className="Adviser-text">
        {setAssociateCode(certification_name)}: {code}
      </span>
		)
	}

	const renderCertification = () => {
		if (!certification_name) return <Fragment/>
		return (
			<span className="Adviser-text">
        Certificación {certification_name}
      </span>
		)
	}

	const renderLocation = () => {
		if (!professional_state) return <Fragment/>
		return (
			<span className="Adviser-text">{professional_state}</span>
		)
	}

	const renderState = () => {
		if (!state) return <Fragment/>
		return (
			<span className="Adviser-text">{state}</span>
		)
	}

	const renderDescription = () => {
		if (!description) return <Fragment/>
		return (
			<span className="Adviser-text Adviser-description">{HtmlParser(description)}</span>
		)
	}


	const renderFacebookIcon = () => {
		if (!facebook) return <Fragment/>

		return (
			<a
				className="Adviser-link"
				href={facebook}
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-facebook-f"></i>
			</a>
		)
	}

	const renderLinkedinInIcon = () => {
		if (!linkedin) return <Fragment/>

		return (
			<a
				className="Adviser-link"
				href={linkedin}
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-linkedin-in"></i>
			</a>
		)
	}

	const renderTwitterIcon = () => {
		if (!twitter) return <Fragment/>

		return (
			<a
				className="Adviser-link"
				href={twitter}
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fa-brands fa-x-twitter"></i>
			</a>
		)
	}

	return (
		<div className="Adviser">
			{renderName()}
			{renderState()}
			{renderCode()}
			{renderCertification()}
			{renderLocation()}
			{renderDescription()}
			<div className="Adviser-social">
				{renderFacebookIcon()}
				{renderLinkedinInIcon()}
				{renderTwitterIcon()}
			</div>
		</div>
	)
}

Adviser.defaultProps = {
	name: "",
	code: "",
	certification_name: "",
	professional_state: "",
	facebook: "",
	linkedin: "",
	twitter: "",
}

Adviser.propTypes = {
	name: Proptypes.string,
	code: Proptypes.string,
	certification_name: Proptypes.string,
	professional_state: Proptypes.string,
	state: Proptypes.string,
	description: Proptypes.string,
	facebook: Proptypes.string,
	linkedin: Proptypes.string,
	twitter: Proptypes.string,
}
