export const paragraph = `
<p>Una vez superado el examen para la obtención de una de las certificaciones de EFPA España, podrá acceder con su usuario y contraseña en
<a href="https://examenes.efpa.es" target="_blank">https://examenes.efpa.es</a> y realizar los trámites para darse de
alta en la asociación</p>
<p>
  Dispone de un periodo máximo de 3 meses* desde la publicación del resultado del examen para enviar la documentación a la sede de EFPA España.
</p>
<p><strong>*NOTA:</strong> Pasada esta fecha, tenga en cuenta que puede haber variaciones tanto en la cuota anual como en las horas de formación continua para la recertificación, ya que dependen de la fecha de superación del examen y no de la fecha de tramitación del alta.                </p>
<p>
 Una vez se haya dado de alta, recibirá un email de bienvenida a la asociación donde se le informará sobre cómo acceder a la intranet de
 la Web donde tendrá acceso a todas las actividades y servicios ofrecidos por EFPA España.
 </p>
`

export const list = [
  {
    content: "EFP: 80 €",
  },
  {
    content: "EFA: 80 €",
  },
  {
    content: "EIP: 65 €",
  },
  {
    content: "EIA: 55 €",
  },
  {
    content: "(*) LCCI: 55 €",
  },
  {
    content: "(*) ESG: 55 €",
  },
  {
    content: "(*) LCCI + ESG: 65 €",
  },
]
