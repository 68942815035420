import React, {useState} from "react";
import ReactPaginate from "react-paginate";
import Adviser from "./Adviser";
import styled from "styled-components";

const MyPaginate = styled(ReactPaginate).attrs({
	activeClassName: 'active',
})`
    margin-bottom: 2rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    list-style-type: none;
    padding: 0 5rem;

    li a {
        border-radius: 7px;
        padding: 0.1rem 1rem;
        border: gray 1px solid;
        cursor: pointer;
    }

    li.previous a,
    li.next a,
    li.break a {
        border-color: transparent;
        color: #112149;
    }

    li.active a {
        background-color: #112149;
        border-color: transparent;
        color: white;
        min-width: 32px;
    }

    li.disabled a {
        color: grey;
    }

    li.disable,
    li.disabled a {
        cursor: default;
    }
`;

export const PaginatedItems = (props) => {
	const {itemsPerPage, items} = props;
	const [itemOffset, setItemOffset] = useState(0);

	const endOffset = itemOffset + itemsPerPage;
	const currentItems = items.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(items.length / itemsPerPage);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % items.length;
		setItemOffset(newOffset);
	};

	return <>
		<div className="row efpa-row">
			{
				currentItems &&
				currentItems.map(item => {
					return (
						<div className="col-12 col-md-3 p-0">
							<Adviser
								name={item.full_name}
								description={item.description}
								state={item.state}
								facebook={item.facebook_link}
								linkedin={item.linkedin_link}
								twitter={item.twitter_link}
								publicUrl={item.public_url}
								showPublicUrl={true}
							/>
						</div>
					)
				})
			}
		</div>
		<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
			<MyPaginate
				breakLabel="..."
				nextLabel="Siguiente"
				onPageChange={handlePageClick}
				pageRangeDisplayed={5}
				pageCount={pageCount}
				previousLabel="Anterior"
				renderOnZeroPageCount={null}/>
		</div>
	</>
}